import React from "react";

const HowWork = () => {
  return (
    <div  id="how" >
    <div className="pt-5" style={{overflow:"hidden"}}>
    <div className="container how pt-5 pb-5">
      <h2 className="pb-5 text-center">How Infinet Works</h2>
     
      <div className="row">
        <div className="col-lg-4 " data-aos="fade-up"
     data-aos-duration="2400">
          <div className="how-card">
            <h3 className="pb-4">1</h3>
            <div className="card-icon">

<img className="pb-4" src="/Images/modem-01.svg" alt="laptop" />
</div>
            <h3>Deploy a Hotspot</h3>
            <p>Set up a hotspot and provide 5G and IoT coverage.</p>
          </div>
        </div>
        <div className="col-lg-4" data-aos="fade-up"
     data-aos-duration="2200">
        <div className="how-card">
            <h3 className="pb-4">2</h3>
            <div className="card-icon">

            <img className="pb-4" src="/Images/laptop.svg" alt="laptop" />
            </div>
            <h3>Connect Devices</h3>
            <p>Connect IoT devices or mobile networks to Infinet for fast, secure data transfers.</p>
          </div>
        </div>
        <div className="col-lg-4" data-aos="fade-up"
     data-aos-duration="2000">
        <div className="how-card">
            <h3 className="pb-4">3</h3>
            <div className="card-icon">

<img className="pb-4" src="/Images/earn.svg" alt="laptop" />
</div>
            <h3>Earn Rewards</h3>
            <p>Earn tokens by providing coverage and participating in network operations.</p>
          </div>
        </div>
        </div>
      </div>
      </div>
      </div>
  );
};

export default HowWork;
