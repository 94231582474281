import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "./Landing";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS directly
function App() {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <>
      <Landing />
    </>
  );
}

export default App;
