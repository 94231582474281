import React from 'react'
import Header from './Header'
import Whychoose from './Whychoose'
import HowWork from './HowWork'
import Features from './Features'
import Token from './Token'
import Roadmap from './Roadmap'
import Teams from './Teams'
import Join from './Join'
import Footer from './Footer'
import Ecosystem from './Ecosystem'
import Omni from './Omni'

const Landing = () => {
  return (
    <div>
      <Header/>
      <Whychoose/>
      <HowWork/>
      <Features/>
      <Omni/>
      <Ecosystem/>
      {/* <Token/> */}
      <Roadmap/>
      {/* <Teams/> */}
      <Join/>
      <Footer/>
    </div>
  )
}

export default Landing
