import React from "react";

const Omni = () => {
  return (
    <div id="OMNI" style={{overflow:"hidden"}}>
      <div  className="pt-5">
    <div className="omni pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="text-center pb-3">
              {" "}
              <span>Infinet OMNI:</span> Your
              Gateway <br />
              to Decentralized Coverage
            </h1>
            <p className="text-center pb-3">
              Powering scalable, secure, and flexible network coverage for both
              IoT and 5G devices.
            </p>
            <div className="text-center pb-3">
              <a href="https://docs.infinetsolutions.io/network-architecture/infinet-omni" target="blank">
              <button>
                Learn More About{" "}
                <span style={{ color: "#826AFF", textDecoration: "underline" }}>
                  Infinet OMNI
                </span>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.92 12.1229C17.8724 12.0001 17.801 11.888 17.71 11.7929L12.71 6.79287C12.6168 6.69963 12.5061 6.62567 12.3842 6.57521C12.2624 6.52475 12.1319 6.49878 12 6.49878C11.7337 6.49878 11.4783 6.60457 11.29 6.79287C11.1968 6.88611 11.1228 6.9968 11.0723 7.11862C11.0219 7.24044 10.9959 7.37101 10.9959 7.50287C10.9959 7.76917 11.1017 8.02457 11.29 8.21287L14.59 11.5029H7C6.73478 11.5029 6.48043 11.6082 6.29289 11.7958C6.10536 11.9833 6 12.2377 6 12.5029C6 12.7681 6.10536 13.0224 6.29289 13.21C6.48043 13.3975 6.73478 13.5029 7 13.5029H14.59L11.29 16.7929C11.1963 16.8858 11.1219 16.9964 11.0711 17.1183C11.0203 17.2402 10.9942 17.3709 10.9942 17.5029C10.9942 17.6349 11.0203 17.7656 11.0711 17.8874C11.1219 18.0093 11.1963 18.1199 11.29 18.2129C11.383 18.3066 11.4936 18.381 11.6154 18.4318C11.7373 18.4825 11.868 18.5087 12 18.5087C12.132 18.5087 12.2627 18.4825 12.3846 18.4318C12.5064 18.381 12.617 18.3066 12.71 18.2129L17.71 13.2129C17.801 13.1178 17.8724 13.0056 17.92 12.8829C18.02 12.6394 18.02 12.3663 17.92 12.1229Z"
                    fill="white"
                  />
                </svg>
              </button></a>
            </div>
          </div>
     
        </div>
        <div className="row pt-5 align-items-center">
               
        <div className="col-lg-4">
            <div className="omni-card" data-aos="fade-up"
     data-aos-duration="2000">
              <h3>Infinet OMNI</h3>
              <p>
                Infinet OMNI is the backbone of our network, enabling easy
                deployment and rewarding users for providing reliable coverage.
              </p>

              <p className="pt-3">Feature Highlights</p>
              <ul>
                <li><img src="/Images/MultipleStopFilled.svg" alt="" /> Multi-Protocol Support</li>
                <li><img src="/Images/MultipleStopFilled.svg" alt="" /> Plug-and-Play Setup</li>
                <li><img src="/Images/MultipleStopFilled.svg" alt="" /> Mobile Hotspot Functionality</li>
                </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="text-center" data-aos="fade-left"
     data-aos-duration="2000">

            <img className="black-infinet" src="/Images/router.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Omni;
