import React from "react";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <img src="/Images/logo2.svg" alt="logo" />
              {/*<h2 className="pt-4">Have any questions?</h2>
              <div className="d-flex header-btn align-items-center gap-4 pt-4">
                <div className="input-groups">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.2">
                      <path
                        d="M15.8334 3.63281H4.16675C3.50371 3.63281 2.86782 3.8962 2.39898 4.36505C1.93014 4.83389 1.66675 5.46977 1.66675 6.13281V14.4661C1.66675 15.1292 1.93014 15.7651 2.39898 16.2339C2.86782 16.7028 3.50371 16.9661 4.16675 16.9661H15.8334C16.4965 16.9661 17.1323 16.7028 17.6012 16.2339C18.07 15.7651 18.3334 15.1292 18.3334 14.4661V6.13281C18.3334 5.46977 18.07 4.83389 17.6012 4.36505C17.1323 3.8962 16.4965 3.63281 15.8334 3.63281V3.63281ZM15.4917 5.29948L10.5917 10.1995C10.5143 10.2776 10.4221 10.3396 10.3206 10.3819C10.219 10.4242 10.1101 10.446 10.0001 10.446C9.89007 10.446 9.78115 10.4242 9.6796 10.3819C9.57805 10.3396 9.48588 10.2776 9.40842 10.1995L4.50841 5.29948H15.4917ZM16.6667 14.4661C16.6667 14.6872 16.579 14.8991 16.4227 15.0554C16.2664 15.2117 16.0544 15.2995 15.8334 15.2995H4.16675C3.94573 15.2995 3.73377 15.2117 3.57749 15.0554C3.42121 14.8991 3.33341 14.6872 3.33341 14.4661V6.47448L8.23342 11.3745C8.70217 11.8426 9.33758 12.1056 10.0001 12.1056C10.6626 12.1056 11.298 11.8426 11.7667 11.3745L16.6667 6.47448V14.4661Z"
                        fill="#F8F8FA"
                      />
                    </g>
                  </svg>

                  <input type="text" placeholder="Enter email" />
                </div>
                <button className="btn-bg">
                  Get in touch{" "}
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9117 12.12C17.8641 11.9973 17.7927 11.8851 17.7017 11.79L12.7017 6.79C12.6085 6.69676 12.4978 6.6228 12.3759 6.57234C12.2541 6.52188 12.1236 6.49591 11.9917 6.49591C11.7254 6.49591 11.47 6.6017 11.2817 6.79C11.1885 6.88324 11.1145 6.99393 11.064 7.11575C11.0136 7.23758 10.9876 7.36814 10.9876 7.5C10.9876 7.7663 11.0934 8.0217 11.2817 8.21L14.5817 11.5H6.9917C6.72648 11.5 6.47213 11.6054 6.28459 11.7929C6.09706 11.9804 5.9917 12.2348 5.9917 12.5C5.9917 12.7652 6.09706 13.0196 6.28459 13.2071C6.47213 13.3946 6.72648 13.5 6.9917 13.5H14.5817L11.2817 16.79C11.188 16.883 11.1136 16.9936 11.0628 17.1154C11.012 17.2373 10.9859 17.368 10.9859 17.5C10.9859 17.632 11.012 17.7627 11.0628 17.8846C11.1136 18.0064 11.188 18.117 11.2817 18.21C11.3747 18.3037 11.4853 18.3781 11.6071 18.4289C11.729 18.4797 11.8597 18.5058 11.9917 18.5058C12.1237 18.5058 12.2544 18.4797 12.3763 18.4289C12.4981 18.3781 12.6087 18.3037 12.7017 18.21L17.7017 13.21C17.7927 13.1149 17.8641 13.0028 17.9117 12.88C18.0117 12.6365 18.0117 12.3635 17.9117 12.12Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>*/}
            </div>
            <div className="col-lg-2 col-md-2 pt-5 pt-md-0">
              <ul className="">
                <a href="#home">
                  <li className="mx-2">Home</li>
                </a>
                <a href="#why">
                  <li className="mx-2">Why INFINET</li>
                </a>
                <a href="#feature">
                  <li className="mx-2">Features</li>
                </a>
                <a href="#token">
                  <li className="mx-2">Token</li>
                </a>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2">
              <ul>
                <a href="#how">
                  <li className="mx-2">How INFINET</li>
                </a>
                <a href="#OMNI">
                  <li className="mx-2">Omni</li>
                </a>
                <a href="#roadmap">
                  <li className="mx-2">Journey</li>
                </a>
                {/* <a href="#join">
                  <li className="mx-2">Join</li>
                </a> */}
              </ul>
            </div>
            <div className="border"></div>
            <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-sm-between align-items-center">
              <p className="m-0 pb-4 pb-sm-0">2024 © INFINET Copyright</p>
              <div className="d-flex align-items-center gap-5">
                <p className=" m-0">Connect with</p>
                <div className="d-flex align-items-center justify-content-center gap-4">
                <a href="https://x.com/Infinet_io" target="blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 44 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33.5981 4.29004H39.7828L26.2711 19.7331L42.1666 40.7477H29.7205L19.9723 28.0024L8.81816 40.7477H2.62971L17.0819 24.2295L1.83325 4.29004H14.5953L23.4068 15.9396L33.5981 4.29004ZM31.4275 37.0458H34.8545L12.7331 7.79745H9.0556L31.4275 37.0458Z"
                      fill="white"
                    />
                  </svg></a>
                  <a href="https://t.me/infinet_solutions" target="blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 38 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8005 23.0984L27.8657 8.97966L10.514 21.5507C10.2233 21.7613 9.84211 21.8 9.5151 21.652L0.713105 17.6702C-0.0879402 17.3078 -0.0650898 16.1624 0.749771 15.8322L37.4131 0.978027L32.3732 33.1289C32.2623 33.8365 31.4651 34.2015 30.8569 33.823L16.0323 24.5971C15.5128 24.2738 15.4029 23.5637 15.8005 23.0984Z"
                      fill="white"
                    />
                  </svg></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
