import React from "react";

const Ecosystem = () => {
  return (
    <div id="token">
      <div className="pt-5 pb-5" style={{ overflow: "hidden" }}>
        <div className="container how pt-5 pb-5">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="pb-5">Token Ecosystem</h2>
            </div>
            <div className="col-lg-7">
              <p>
                The three-token system designed to seperate key functions and
                improve scalability flexibility within the network.
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-4 "
              data-aos="fade-up"
              data-aos-duration="2400"
            >
              <div className="how-card eco">
                <img className="w-100" src="/Images/Insert.svg" alt="laptop" />

                <h3
                  style={{ fontSize: 24, lineHeight: "30px" }}
                  className="mt-5"
                >
                  INFI
                </h3>
                <h4 style={{ color: "#7D6CFF", fontSize: "16px" }}>
                  Governance Token
                </h4>
                <p className="pt-3">
                  INFI is the primary governance token, allowing holders to vote
                  on network proposals, staking rewards, and protocol upgrades.
                  It plays a crucial role in securing the network and provide
                  voice to the community.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="2200"
            >
              <div className="how-card eco">
                <img
                  className="w-100"
                  src="/Images/Insert(1).svg"
                  alt="laptop"
                />

                <h3
                  style={{ fontSize: 24, lineHeight: "30px" }}
                  className="mt-5"
                >
                  NET{" "}
                </h3>
                <h4 style={{ color: "#7D6CFF", fontSize: "16px" }}>
                  Network Usability Tokens
                </h4>
                <p className="pt-3">
                  NET is the operational token used for transaction fees,
                  Proof-of-Coverage rewards, and service payments within the
                  network. it ensure smooth network operation by facilitating
                  payment between users, operators, and service providers.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="how-card eco">
                <img
                  className="w-100"
                  src="/Images/Insert(2).svg"
                  alt="laptop"
                />

                <h3
                  style={{ fontSize: 24, lineHeight: "30px" }}
                  className="mt-5"
                >
                  CONNECT
                </h3>
                <h4 style={{ color: "#7D6CFF", fontSize: "16px" }}>
                  5G Network Token
                </h4>
                <p className="pt-3">
                  CONNECT is specifically designed for 5G Network usage,
                  enabling payments and rewards related to high-bandwidth
                  applications and mobile devices. It ensure that the 5G side of
                  the network remain optimized and responsive to the user.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
