import React from "react";

const Whychoose = () => {
  return (
    <div id="why" style={{overflow:"hidden"}}>
    <div className="container why pt-5">
      <div className="row align-items-center ">
        <div className="col-lg-5 order-2 order-lg-1"  data-aos="fade-right"
     data-aos-duration="2000">
          {" "}
          <img className="main-why" src="/Images/why.svg" alt="why" />
        </div>
        <div className="col-lg-7  order-1 order-lg-2"  data-aos="fade-left"
     data-aos-duration="2000">
          <h2>Why Infinet?</h2>
          <p>
            Infinet provides a decentralized, privacy-focused, and scalable
            solution for IoT and 5G networks. By leveraging zk-Rollups and
            multi-protocol support, Infinet offers low-cost transactions,
            seamless connectivity, and a truly global network built by and for
            the community.
          </p>
          <div className="row pt-4">
            <div className="col-lg-6 col-sm-6 pb-4">
              <div className="d-flex align-items-center gap-3 pb-3">
                <img src="/Images/scale.svg" alt="" />
                <h3>Scalable </h3>
              </div>
              <p>Scalable Network with zk-Rollups</p>
            </div>
            <div className="col-lg-6 col-sm-6 pb-4">
              <div className="d-flex align-items-center gap-3 pb-3">
                <img src="/Images/s5g.svg" alt="" />
                <h3>5G Support </h3>
              </div>
              <p> Integrated 5G Support</p>
            </div>{" "}
            <div className="col-lg-6 col-sm-6 pb-4">
              <div className="d-flex align-items-center gap-3 pb-3">
                <img src="/Images/enchance.svg" alt="" />
                <h3>Enhanced Privacy </h3>
              </div>
              <p>Enhanced Privacy and Security</p>
            </div>{" "}
            <div className="col-lg-6 col-sm-6 pb-4">
              <div className="d-flex align-items-center gap-3 pb-3">
                <img src="/Images/last.svg" alt="" />
                <h3>Community-Driven </h3>
              </div>
              <p>Community-Driven and Decentralized</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Whychoose;
