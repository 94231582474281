import React from "react";

const Features = () => {
  return (
    <div id="feature" style={{overflow:"hidden"}} className=" position-relative">
    <div className="container pt-5 pb-5 feature">
      <div className="row justify-content-center align-items-center pt-5">
      <img style={{width:"15%"}} className="position-absolute end-0" src="/Images/Vector 14.svg" alt="" />
     
     <img  style={{width:"15%"}}  className="position-absolute  start-0 "src="/Images/Vector 15.svg" alt="" />
        <div className="col-lg-8">
          <h2 className="text-center pb-5">Core Features</h2>
          <div className="row">
            <div className="col-lg-6  col-md-6 pb-5" data-aos="fade-right"
     data-aos-duration="2000">
              <div className="d-flex align-items-start gap-4">
                <img src="/Images/multi.svg" alt="" />
                <div>
                  <h3>Multi-Protocol Support</h3>
                  <p>Seamless connectivity for IoT and 5G devices.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  pb-5" data-aos="fade-left"
     data-aos-duration="2000">
            <div className="d-flex align-items-start gap-4">
                <img src="/Images/privacy.svg" alt="" />
                <div>
                  <h3>Enhanced Privacy</h3>
                  <p>Zero-knowledge proofs ensure data privacy and security</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-md-6 pb-5" data-aos="fade-right"
     data-aos-duration="2000">
            <div className="d-flex align-items-start gap-4">
                <img src="/Images/o.svg" alt="" />
                <div>
                  <h3>Dynamic Resource Allocation</h3>
                  <p>Prioritized bandwidth for critical applications.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-md-6 pb-5" data-aos="fade-left"
     data-aos-duration="2000">
            <div className="d-flex align-items-start gap-4">
                <img src="/Images/scal.svg" alt="" />
                <div>
                  <h3>Scalable zk-Rollups</h3>
                  <p>High throughput and low fees with zk-Rollups.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Features;
