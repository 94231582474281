import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = ({ handleShow }) => {
  return (
    <div className="main-header" id="home">
      <video className="home-bg" loop autoPlay muted playsinline preload="auto">
        {" "}
        <source src="/Videos/infinetvideo.mp4" type="video/mp4" />
        <source src="/Videos/infinetvideo.webm" type="video/webm" />
        <source src="/Videos/infinetvideo.ogv" type="video/ogg" />
      </video>

      <div className="nav-cus ">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-white navbar-brand">
              <img src="/Images/logo2.svg" alt="" />
            </div>
            <div>
              <ul className="d-flex">
                <a href="#home">
                  <li className="mx-2">Home</li>
                </a>
                <a href="#why">
                  <li className="mx-2">Why INFINET</li>
                </a>
                <a href="#how">
                  <li className="mx-2">How INFINET</li>
                </a>
                <a href="#feature">
                  <li className="mx-2">Features</li>
                </a>
                <a href="#OMNI">
                  <li className="mx-2">Omni</li>
                </a>
                <a href="#token">
                  <li className="mx-2">Token</li>
                </a>
                <a href="#roadmap">
                  <li className="mx-2">Journey</li>
                </a>

                {/* <a href="#join">
                  <li className="mx-2">Join</li>
                </a> */}
              </ul>
            </div>
            <a className="btn-bg text-white" href="https://linktr.ee/InfinetSolutions" target="blank">
              Join INFINET{" "}
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9117 12.12C17.8641 11.9973 17.7927 11.8851 17.7017 11.79L12.7017 6.79C12.6085 6.69676 12.4978 6.6228 12.3759 6.57234C12.2541 6.52188 12.1236 6.49591 11.9917 6.49591C11.7254 6.49591 11.47 6.6017 11.2817 6.79C11.1885 6.88324 11.1145 6.99393 11.064 7.11575C11.0136 7.23758 10.9876 7.36814 10.9876 7.5C10.9876 7.7663 11.0934 8.0217 11.2817 8.21L14.5817 11.5H6.9917C6.72648 11.5 6.47213 11.6054 6.28459 11.7929C6.09706 11.9804 5.9917 12.2348 5.9917 12.5C5.9917 12.7652 6.09706 13.0196 6.28459 13.2071C6.47213 13.3946 6.72648 13.5 6.9917 13.5H14.5817L11.2817 16.79C11.188 16.883 11.1136 16.9936 11.0628 17.1154C11.012 17.2373 10.9859 17.368 10.9859 17.5C10.9859 17.632 11.012 17.7627 11.0628 17.8846C11.1136 18.0064 11.188 18.117 11.2817 18.21C11.3747 18.3037 11.4853 18.3781 11.6071 18.4289C11.729 18.4797 11.8597 18.5058 11.9917 18.5058C12.1237 18.5058 12.2544 18.4797 12.3763 18.4289C12.4981 18.3781 12.6087 18.3037 12.7017 18.21L17.7017 13.21C17.7927 13.1149 17.8641 13.0028 17.9117 12.88C18.0117 12.6365 18.0117 12.3635 17.9117 12.12Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="mobile-menu ">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between pt-2">
            <div className="text-white navbar-brand">
              {" "}
              <img src="/Images/logo2.svg" alt="" />
            </div>
            <div onClick={handleShow} style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                style={{ fill: "#FFFFFF" }}
              >
                <path d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="container header-content ">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <h1 className="">
              Next-Gen Decentralized <br /> Network for IoT and 5G
            </h1>
            <p className="text-center pt-3">
              Scalable. Secure. Infinite Connectivity
            </p>
            <div className="text-center header-btn d-flex align-items-center justify-content-center gap-2 pt-5">
              <a href="https://linktr.ee/InfinetSolutions" target="blank">
                <button className="btn-bg">
                  Join INFINET{" "}
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9117 12.12C17.8641 11.9973 17.7927 11.8851 17.7017 11.79L12.7017 6.79C12.6085 6.69676 12.4978 6.6228 12.3759 6.57234C12.2541 6.52188 12.1236 6.49591 11.9917 6.49591C11.7254 6.49591 11.47 6.6017 11.2817 6.79C11.1885 6.88324 11.1145 6.99393 11.064 7.11575C11.0136 7.23758 10.9876 7.36814 10.9876 7.5C10.9876 7.7663 11.0934 8.0217 11.2817 8.21L14.5817 11.5H6.9917C6.72648 11.5 6.47213 11.6054 6.28459 11.7929C6.09706 11.9804 5.9917 12.2348 5.9917 12.5C5.9917 12.7652 6.09706 13.0196 6.28459 13.2071C6.47213 13.3946 6.72648 13.5 6.9917 13.5H14.5817L11.2817 16.79C11.188 16.883 11.1136 16.9936 11.0628 17.1154C11.012 17.2373 10.9859 17.368 10.9859 17.5C10.9859 17.632 11.012 17.7627 11.0628 17.8846C11.1136 18.0064 11.188 18.117 11.2817 18.21C11.3747 18.3037 11.4853 18.3781 11.6071 18.4289C11.729 18.4797 11.8597 18.5058 11.9917 18.5058C12.1237 18.5058 12.2544 18.4797 12.3763 18.4289C12.4981 18.3781 12.6087 18.3037 12.7017 18.21L17.7017 13.21C17.7927 13.1149 17.8641 13.0028 17.9117 12.88C18.0117 12.6365 18.0117 12.3635 17.9117 12.12Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </a>

              <a href="https://docs.infinetsolutions.io" target="blank"><button className="btn-black">Learn More</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

function OffCanvasExample() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Pass handleShow to Header */}
      <Header handleShow={handleShow} />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <div className="text-white navbar-brand">
              {" "}
              <img src="/Images/logo2.svg" alt="" />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <ul className="">
              <a href="#home" onClick={handleClose}>
                <li className="mx-2">Home</li>
              </a>
              <a href="#why" onClick={handleClose}>
                <li className="mx-2">Why Infinet</li>
              </a>
              <a href="#how"  onClick={handleClose}>
                  <li className="mx-2">How INFINET</li>
                </a>
              <a href="#feature" onClick={handleClose}>
                <li className="mx-2">Features</li>
              </a>
              <a href="#OMNI"  onClick={handleClose}>
                <li className="mx-2">OMNI</li>
              </a>
              <a href="#token" onClick={handleClose}>
                <li className="mx-2">Token</li>
              </a>
              <a href="#roadmap" onClick={handleClose}>
                <li className="mx-2">Journey</li>
              </a>
             
            
            </ul>
            <a
              className="btn-bg text-white"
              href="#footer"
              onClick={handleClose}
            >
              Get in touch{" "}
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9117 12.12C17.8641 11.9973 17.7927 11.8851 17.7017 11.79L12.7017 6.79C12.6085 6.69676 12.4978 6.6228 12.3759 6.57234C12.2541 6.52188 12.1236 6.49591 11.9917 6.49591C11.7254 6.49591 11.47 6.6017 11.2817 6.79C11.1885 6.88324 11.1145 6.99393 11.064 7.11575C11.0136 7.23758 10.9876 7.36814 10.9876 7.5C10.9876 7.7663 11.0934 8.0217 11.2817 8.21L14.5817 11.5H6.9917C6.72648 11.5 6.47213 11.6054 6.28459 11.7929C6.09706 11.9804 5.9917 12.2348 5.9917 12.5C5.9917 12.7652 6.09706 13.0196 6.28459 13.2071C6.47213 13.3946 6.72648 13.5 6.9917 13.5H14.5817L11.2817 16.79C11.188 16.883 11.1136 16.9936 11.0628 17.1154C11.012 17.2373 10.9859 17.368 10.9859 17.5C10.9859 17.632 11.012 17.7627 11.0628 17.8846C11.1136 18.0064 11.188 18.117 11.2817 18.21C11.3747 18.3037 11.4853 18.3781 11.6071 18.4289C11.729 18.4797 11.8597 18.5058 11.9917 18.5058C12.1237 18.5058 12.2544 18.4797 12.3763 18.4289C12.4981 18.3781 12.6087 18.3037 12.7017 18.21L17.7017 13.21C17.7927 13.1149 17.8641 13.0028 17.9117 12.88C18.0117 12.6365 18.0117 12.3635 17.9117 12.12Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasExample;
