import React from "react";

const Roadmap = () => {
  return (
    <div id="roadmap" className=" position-relative roadmap">
      <div className="container pt-5 pb-5 feature">
        <div className="row justify-content-center align-items-center ">
          <div className="pb-5">
            <h2 className="pb-5">Our Journey</h2>
          </div>
          <img
            style={{ width: "20%", top: "0px" }}
            className="position-absolute end-0 left"
            src="/Images/rightroad.svg"
            alt=""
          />

          <img
            style={{ width: "20%" }}
            className="position-absolute  start-0 right"
            src="/Images/leftroad.svg"
            alt=""
          />
          <img
            style={{ width: "35%", top: "50%", right: "0px" }}
            className="position-absolute rightb"
            src="/Images/rightb.svg"
            alt=""
          />
          <img
            style={{ width: "35%", bottom: "0px", left: "0px" }}
            className="position-absolute rightb"
            src="/Images/leftb.svg"
            alt=""
          />
          <div className="mx-auto main-road  position-relative">
            <div className="timeline">
              <div className="d-flex align-items-center gap-4  timeline-item ">
                <div className="connector">
                  <div className="marker"></div>
                  <div className="year ">
                    <div className="inner-year">Q3 2024</div>
                  </div>
                </div>
                <div className="content">
                  <h3>Foundation and Community Building</h3>

                  <ul>
                    <li>
                      <p>INFI Token Launch</p>
                    </li>
                    <li>
                      <p>Community Building</p>
                    </li>
                    <li>
                      <p>Testnet Launch</p>
                    </li>
                    <li>
                      <p>Initial Staking and Delegation Setup</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-center  gap-4 timeline-item  ">
                <div className="connector">
                  <div className="marker"></div>
                  <div className="year ">
                    <div className="inner-year">Q4 2024</div>
                  </div>
                </div>
                <div className="content">
                  <h3>Mainnet and Second Token Launch</h3>
                  <ul>
                    <li>
                      <p>Mainnet Launch</p>
                    </li>
                    <li>
                      <p>NET Token Launch</p>
                    </li>
                    <li>
                      <p>CEX Listing (INFI and NET)</p>
                    </li>
                    <li>
                      <p>Initial Coverage Expansion</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-start  gap-4 timeline-item ">
                <div className="connector">
                  <div className="marker"></div>
                  <div className="year">
                    <div className="inner-year">Q1 2025</div>
                  </div>
                </div>
                <div className="content">
                  <h3>5G Integration and Ecosystem Growth</h3>
                  <ul>
                    <li>
                      <p>5G Integration</p>
                    </li>
                    <li>
                      <p>CONNECT Token Launch</p>
                    </li>
                    <li>
                      <p>Decentralized Marketplace Launch</p>
                    </li>
                    <li>
                      <p>Partnerships and Collaborations</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-start  gap-4 timeline-item ">
                <div className="connector">
                  <div className="marker"></div>
                  <div className="year">
                    <div className="inner-year">2025 Q2-Q4</div>
                  </div>
                </div>
                <div className="content">
                  <h3>Network Scaling and Global Expansion</h3>
                  <ul>
                    <li>
                      <p>Mass Deployment of Infinet OMNI Devices</p>
                    </li>
                    <li>
                      <p>Advanced Proof-of-Coverage Upgrades</p>
                    </li>
                    <li>
                      <p>5G Network Expansion</p>
                    </li>
                    <li>
                      <p>Sustainability Initiatives</p>
                    </li>
                    <li>
                      <p>DAO and Governance Implementation</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex align-items-start  gap-4 timeline-item ">
                <div className="connector">
                  <div className="marker"></div>
                  <div className="year">
                    <div className="inner-year">2026 and Beyond</div>
                  </div>
                </div>
                <div className="content">
                  <h3>Future Innovations and Ecosystem Maturity</h3>
                  <ul>
                    <li>
                      <p>Global Network Optimization</p>
                    </li>
                    <li>
                      <p>6G Preparations</p>
                    </li>
                    <li>
                      <p>Advanced Use Cases</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
